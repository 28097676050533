import React, { Fragment, useEffect, useState } from "react";
import { TitleWithEditCard } from "../../components/cards/TitleWithEditCard";
import { ItemsLabelRow } from "../../components/toolkit/ItemsLabelRow";
import { ProfileCard } from "../../components/cards/ProfileCard";
import ProfileTabs from "../../components/my_profile/ProfileTabs";
import { useDispatch, useSelector } from "react-redux";
import { Select as AntdSelect, Checkbox, Tooltip } from "antd";
import API from "../../helpers/devApi";
import { swalError, swalSuccess } from "../../helpers/swal";
import moment from "moment";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import * as Action from "./_redux/recruitementActions";
import * as JobAction from "../../_redux/jobActions";
import Select from "react-select";
import { Table } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";

const socialMediaOptions = [
  {
    platform: "Facebook",
    label: "Facebook",
    value: "Facebook",
    url: "",
  },
  {
    platform: "Linkedin",
    label: "Linkedin",
    value: "Linkedin",
    url: "",
  },
  {
    platform: "Youtube",
    label: "Youtube",
    value: "Youtube",
    url: "",
  },
  {
    platform: "X",
    label: "X",
    value: "X",
    url: "",
  },
  {
    platform: "Vimeo",
    label: "Vimeo",
    value: "Vimeo",
    url: "",
  },
  {
    platform: "Instagram",
    label: "Instagram",
    value: "Instagram",
    url: "",
  },
  {
    platform: "",
    label: "Other",
    value: "Other",
    url: "",
  },
];

const religions = [
  {
    value: "Islam",
    label: "Islam",
  },
  {
    value: "Hinduism",
    label: "Hinduism",
  },
  {
    value: "Buddhism",
    label: "Buddhism",
  },
  {
    value: "Christianity",
    label: "Christianity",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const socialMediaDefined = {
  // Facebook: 'fa fa-facebook-official',
  // Linkedin: 'fa fa-linkedin',
  // Youtube: 'fa fa-youtube-play',
  // Twitter: 'fa fa-twitter',
  // Vimeo: 'fa fa-vimeo',
  // Instagram: 'fa fa-instagram'
  Facebook: "fab fa-facebook",
  Linkedin: "fab fa-linkedin",
  Youtube: "fab fa-youtube",
  Twitter: "fab fa-twitter",
  Vimeo: "fab fa-vimeo",
  Instagram: "fab fa-instagram",
  X: "fas fa-times",
};

export default function PersonalInfo() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const {
    applicantsInfo,
    pre_district,
    pre_thana,
    per_district,
    per_thana,
    social_medias,
    categoryList,
  } = useSelector(({ recruitement, social_medias, job }) => ({
    applicantsInfo: recruitement.applicantsInfo,
    pre_district: recruitement.pre_district,
    pre_thana: recruitement.pre_thana,
    per_district: recruitement.per_district,
    per_thana: recruitement.per_thana,
    social_medias: recruitement.social_medias,
    categoryList: job.categoryList,
  }));

  const [categories, setCategories] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [perInitialData, setPerInitialData] = useState({
    per_address_line_1: "",
    per_address_line_2: "",
    per_districts_id: null,
    per_divisions_id: null,
    per_thana_id: null,
    per_international_address: "",
    per_postal_code: "",
  });
  const [preInitialData, setPreInitialData] = useState({
    pre_address_line_1: "",
    pre_address_line_2: "",
    pre_districts_id: null,
    pre_divisions_id: null,
    pre_thana_id: null,
    pre_international_address: "",
    pre_postal_code: "",
    is_same_as_permanent_address: "",
  });
  const [socialMedias, setSocialMedias] = useState({ social_medias: [] });
  const [selectedData, setSelectedData] = useState({});
  const [showEditOne, setShowEditOne] = useState(false);
  const [showEditTwo, setShowEditTwo] = useState(false);
  const [showEditSocialMedia, setShowEditSocialMedia] = useState(false);
  const [divisionList, setDivisionList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [upazilaList, setUpazilaList] = useState([]);
  const profileScoreArr = [
    "Personal Information",
    "Ex-Brac Employee",
    "Present Address",
    "Permanent Address",
  ];

  useEffect(() => {
    if (user?.data?.applicant_id) {
      dispatch(Action.getApplicantsInfoById(user?.data?.applicant_id));
      dispatch(JobAction.fetchALLCategory());
    }
  }, [user, dispatch]);

  useEffect(() => {
    if(categoryList) {
      setCategories(categoryList?.hrms_applicant_category?.map((item) => ({
        label: item?.hrms_applicant_category__name,
        value: item?.hrms_applicant_category__id,
      })));
    }
  }, [categoryList]);

  const getCountryInfo = async () => {
    await API.get(`config_countries/`)
      .then((res) => {
        if (res.data?.success) {
          const resData = res?.data?.data?.config_countries;
          var result = resData?.map(function (col) {
            return {
              value: col?.config_countries__country_id,
              label: col?.config_countries__country_name,
            };
          });
          setCountryList(result);
        } else {
          swalError(res?.message);
        }
      })
      .catch((error) => {
        swalError("someting went wrong");
      });
  };

  const getNationalityInfo = async () => {
    await API.get(`hrms_nationality/`)
      .then((res) => {
        if (res.data?.success) {
          const resData = res?.data?.data?.hrms_nationality;
          var result = resData?.map(function (col) {
            return {
              value: col?.hrms_nationality__id,
              label: col?.hrms_nationality__name,
            };
          });
          setNationalityList(result);
        } else {
          swalError(res?.message);
        }
      })
      .catch((error) => {
        swalError("someting went wrong");
      });
  };

  useEffect(() => {
    const [feet, inch] = (applicantsInfo?.height || "").split("'");

    setSelectedData({
      first_name: applicantsInfo?.m_career_portal_user?.first_name,
      middle_name: applicantsInfo?.m_career_portal_user?.middle_name,
      last_name: applicantsInfo?.m_career_portal_user?.last_name,
      date_of_birth: moment(applicantsInfo?.date_of_birth).format("YYYY-MM-DD"),
      father_name: applicantsInfo?.father_name,
      mother_name: applicantsInfo?.mother_name,
      religion: applicantsInfo?.religion,
      other_religion_name: applicantsInfo?.other_religion_name,
      gender: applicantsInfo?.gender,
      marital_status: applicantsInfo?.marital_status,
      country_of_birth: applicantsInfo?.country_of_birth,
      nationality_id: applicantsInfo?.nationality_id,
      nationality_type: applicantsInfo?.nationality_type,
      second_nationality_id: applicantsInfo?.second_nationality_id,
      second_nationality_type: applicantsInfo?.second_nationality_type,
      nid: applicantsInfo?.nid,
      blood_group: applicantsInfo?.blood_group,
      passport_number: applicantsInfo?.passport_number,
      pin: applicantsInfo?.pin,
      contact_number: applicantsInfo?.m_career_portal_user?.contact_number,
      email: applicantsInfo?.m_career_portal_user?.email,
      alternative_contact_number: applicantsInfo?.alternative_contact_number,
      alternative_email: applicantsInfo?.alternative_email,
      is_disable: applicantsInfo?.is_disable,
      about_disability: applicantsInfo?.about_disability,
      is_ex_employee: applicantsInfo?.is_ex_employee,
      prev_joining_date: applicantsInfo?.prev_joining_date
        ? moment(applicantsInfo?.prev_joining_date).format("YYYY-MM-DD")
        : null,
      prev_last_working_date: applicantsInfo?.prev_last_working_date
        ? moment(applicantsInfo?.prev_last_working_date).format("YYYY-MM-DD")
        : null,
      ex_employee_code: applicantsInfo?.ex_employee_code,
      ex_superviosor_name: applicantsInfo?.ex_superviosor_name,
      ex_superviosor_designation: applicantsInfo?.ex_superviosor_designation,
      ex_superviosor_email: applicantsInfo?.ex_superviosor_email,
      ex_superviosor_phone: applicantsInfo?.ex_superviosor_phone,
      spouse_name: applicantsInfo?.spouse_name,
      height_ft: feet,
      height_in: inch?.substring(0, inch.length - 1),
      weight: applicantsInfo?.weight,
      tin: applicantsInfo?.tin,
      // 'no_of_living_child': applicantsInfo?.no_of_living_child,
      work_permit_expiry_date: applicantsInfo?.work_permit_expiry_date
        ? moment(applicantsInfo?.work_permit_expiry_date).format("YYYY-MM-DD")
        : null,
      work_permit_issue_date: applicantsInfo?.work_permit_issue_date
        ? moment(applicantsInfo?.work_permit_issue_date).format("YYYY-MM-DD")
        : null,
      passport_issue_date: applicantsInfo?.passport_issue_date
        ? moment(applicantsInfo?.passport_issue_date).format("YYYY-MM-DD")
        : null,
      passport_expiry_date: applicantsInfo?.passport_expiry_date
        ? moment(applicantsInfo?.passport_expiry_date).format("YYYY-MM-DD")
        : null,
      visa_expiry_date: applicantsInfo?.visa_expiry_date
        ? moment(applicantsInfo?.visa_expiry_date).format("YYYY-MM-DD")
        : null,
      category_id: applicantsInfo?.category_id,
    });

    setPerInitialData({
      per_address_line_1: applicantsInfo?.per_address_line_1,
      per_address_line_2: applicantsInfo?.per_address_line_2,
      per_districts_id: applicantsInfo?.per_districts_id,
      per_divisions_id: applicantsInfo?.per_divisions_id,
      per_thana_id: applicantsInfo?.per_thana_id,
      per_international_address: applicantsInfo?.per_international_address,
      per_postal_code: applicantsInfo?.per_postal_code,
    });

    setPreInitialData({
      pre_address_line_1: applicantsInfo?.pre_address_line_1,
      pre_address_line_2: applicantsInfo?.pre_address_line_2,
      pre_international_address: applicantsInfo?.pre_international_address,
      pre_postal_code: applicantsInfo?.pre_postal_code,
      pre_districts_id: applicantsInfo?.pre_districts_id,
      pre_divisions_id: applicantsInfo?.pre_divisions_id,
      pre_thana_id: applicantsInfo?.pre_thana_id,
      is_same_as_permanent_address:
        applicantsInfo?.is_same_as_permanent_address,
    });

    setIsChecked(applicantsInfo?.is_same_as_permanent_address ? true : false);

    if (applicantsInfo?.per_divisions_id) {
      dispatch(
        Action.getAllPerDistrictByDivision(applicantsInfo?.per_divisions_id)
      );
    }
    if (applicantsInfo?.per_divisions_id && applicantsInfo?.per_districts_id) {
      dispatch(
        Action.getAllPerThanaDistrictByDivision(
          applicantsInfo?.per_divisions_id,
          applicantsInfo?.per_districts_id
        )
      );
    }

    if (applicantsInfo?.pre_divisions_id) {
      dispatch(
        Action.getAllPreDistrictByDivision(applicantsInfo?.pre_divisions_id)
      );
    }
    if (applicantsInfo?.pre_divisions_id && applicantsInfo?.pre_districts_id) {
      dispatch(
        Action.getAllPreThanaDistrictByDivision(
          applicantsInfo?.pre_divisions_id,
          applicantsInfo?.pre_districts_id
        )
      );
    }

    dispatch(Action.getApplicantsSocialMedia(user?.data?.applicant_id));
  }, [applicantsInfo]);

  useEffect(() => {
    setSocialMedias({ social_medias });
  }, [social_medias]);

  const PersonalSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(255, "Too Long!")
      .nullable()
      .required("Required"),
    last_name: Yup.string()
      // .min(2, 'Too Short!')
      .max(255, "Too Long!")
      .nullable(),
    // .required('Required'),
    date_of_birth: Yup.date().nullable().required("Required"),
    father_name: Yup.string()
      .min(2, "Too Short!")
      .max(255, "Too Long!")
      .nullable()
      .required("Required"),
    mother_name: Yup.string()
      .min(2, "Too Short!")
      .max(255, "Too Long!")
      .nullable()
      .required("Required"),
    religion: Yup.string()
      // .min(2, 'Too Short!')
      // .max(30, 'Too Long!')
      .nullable()
      .required("Required"),
    other_religion_name: Yup.string().when(["religion"], (religion, schema) => {
      if (religion === "Other") {
        return Yup.string().nullable().required("Required");
      } else {
        return Yup.string().nullable();
      }
    }),
    gender: Yup.string()
      .min(2, "Too Short!")
      .max(255, "Too Long!")
      .nullable()
      .required("Required"),
    marital_status: Yup.string().nullable().required("Required"),
    spouse_name: Yup.string().when("marital_status", {
      is: "Married",
      then: Yup.string().nullable().required("Spouse name is required"),
      otherwise: Yup.string().nullable(),
    }),
    blood_group: Yup.string().nullable().required("Required"),
    country_of_birth: Yup.number().nullable().required("Required"),
    nationality_id: Yup.number().nullable().required("Required"),
    second_nationality_id: Yup.number().nullable(),
    nationality_type: Yup.string()
      .min(2, "Too Short!")
      .max(255, "Too Long!")
      .nullable()
      .required("Required"),
    second_nationality_type: Yup.string()
      .min(2, "Too Short!")
      .max(255, "Too Long!")
      .nullable(),
    nid: Yup.number().when("nationality_id", {
      is: 15,
      then: Yup.number().nullable().required("Required"),
      otherwise: Yup.number().nullable(),
    }),
    passport_number: Yup.string().when(
      ["nationality_id"],
      (nationality_id, schema) => {
        return nationality_id !== 15
          ? Yup.string().nullable().required("Required")
          : Yup.string().nullable();
      }
    ),
    is_disable: Yup.number()
      .nullable()
      .typeError("Required")
      .required("Required"),
    is_ex_employee: Yup.number()
      .nullable()
      .typeError("Required")
      .required("Required"),

    ex_employee_code: Yup.string().when("is_ex_employee", {
      is: 1,
      then: Yup.string().nullable().required("Employee code is required"),
      otherwise: Yup.string().nullable(),
    }),

    prev_joining_date: Yup.date().when("is_ex_employee", {
      is: 1,
      then: Yup.date().nullable().required("Joining Date is required"),
      otherwise: Yup.date().nullable(),
    }),

    // prev_last_working_date: Yup.string().when('is_ex_employee', {
    //     is: "1",
    //     then: Yup.string().nullable().required('Last working day is required'),
    //     otherwise: Yup.string().nullable()
    // }),
    prev_last_working_date: Yup.string().when(
      ["is_ex_employee", "prev_joining_date"],
      (is_ex_employee, prev_joining_date) => {
        if (is_ex_employee === 1) {
          if (prev_joining_date) {
            return Yup.date()
              .nullable()
              .required("Last working day is required")
              .min(
                prev_joining_date,
                "Last working date must be greater than or equal joining date"
              );
          } else {
            return Yup.date()
              .nullable()
              .required("Last working day is required");
          }
        } else {
          return Yup.date().nullable();
        }
      }
    ),
    ex_superviosor_email: Yup.string().when("is_ex_employee", {
      is: 1,
      then: Yup.string().email("Must be valid email").nullable(),
      otherwise: Yup.string().nullable(),
    }),
    ex_superviosor_phone: Yup.string().when("is_ex_employee", {
      is: 1,
      then: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .max(11, "Too Long!")
        .nullable(),
      otherwise: Yup.string().nullable(),
    }),
    contact_number: Yup.string().nullable().required("Required"),
    email: Yup.string().nullable().email("Invalid email").required("Required"),
    // no_of_living_child: Yup.number().nullable(),
    alternative_email: Yup.string().nullable().email("Invalid email"),
    alternative_contact_number: Yup.string().nullable().required("Required"),
    about_disability: Yup.string().when(["is_disable"], (is_disable) => {
      if (is_disable === 1) {
        return Yup.string().nullable().required("Required");
      } else {
        return Yup.string().nullable();
      }
    }),
    work_permit_expiry_date: Yup.date().when(
      ["work_permit_issue_date"],
      (work_permit_issue_date) => {
        if (work_permit_issue_date) {
          return Yup.date()
            .nullable()
            .min(
              work_permit_issue_date,
              "Expire date must be greater than or equal issue date"
            );
        } else {
          return Yup.date().nullable();
        }
      }
    ),
    passport_expiry_date: Yup.date().when(
      ["passport_issue_date"],
      (passport_issue_date) => {
        if (passport_issue_date) {
          return Yup.date()
            .nullable()
            .min(
              passport_issue_date,
              "Expire date must be greater than or equal issue date"
            );
        } else {
          return Yup.date().nullable();
        }
      }
    ),
    category_id: Yup.number().nullable().required("Job Category is required"),
  });

  const PermanentAddressSchema = Yup.object().shape({
    per_address_line_1: Yup.string().required("Full address is required"),
    per_divisions_id: Yup.string().nullable(),
    per_districts_id: Yup.string().nullable(),
    per_thana_id: Yup.string().nullable(),
  });

  const PresentAddressSchema = Yup.object().shape({
    pre_address_line_1: Yup.string().required("Full address is required"),
    pre_divisions_id: Yup.string().nullable(),
    pre_districts_id: Yup.string().nullable(),
    pre_thana_id: Yup.string().nullable(),
  });

  const SocialMediaSchema = Yup.object().shape({
    social_medias: Yup.array().of(
      Yup.object().shape({
        platform: Yup.string().required("Social media is required"),
        url: Yup.string().required("Url is required"),
      })
    ),
  });

  useEffect(() => {
    if (user?.data?.applicant_id) {
      dispatch(Action.getApplicantsInfoById(user?.data?.applicant_id));
    }
  }, [user, dispatch]);

  const getDivisionInfo = async () => {
    await API.get(`sys_geo_divisions/`)
      .then((res) => {
        if (res.data?.success) {
          const resData = res?.data?.data?.sys_geo_divisions;
          var result = resData?.map(function (col) {
            return {
              value: col?.sys_geo_divisions__geo_division_id,
              label: col?.sys_geo_divisions__geo_division_name,
            };
          });
          setDivisionList(result);
        } else {
          swalError(res?.message);
        }
      })
      .catch((error) => {
        swalError("someting went wrong");
      });
  };

  const getDistrictInfo = async () => {
    await API.get(`sys_geo_districts/`)
      .then((res) => {
        if (res.data?.success) {
          const resData = res?.data?.data?.sys_geo_districts;
          var result = resData?.map(function (col) {
            return {
              value: col?.sys_geo_districts__geo_district_id,
              label: col?.sys_geo_districts__geo_district_name,
            };
          });
          setDistrictList(result);
        } else {
          swalError(res?.message);
        }
      })
      .catch((error) => {
        swalError("someting went wrong");
      });
  };

  const getUpazilatInfo = async () => {
    await API.get(`hrms_thana/`)
      .then((res) => {
        if (res.data?.success) {
          const resData = res?.data?.data?.hrms_thana;
          var result = resData?.map(function (col) {
            return {
              value: col?.hrms_thana__id,
              label: col?.hrms_thana__name,
            };
          });
          setUpazilaList(result);
        } else {
          swalError(res?.message);
        }
      })
      .catch((error) => {
        swalError("someting went wrong");
      });
  };

  useEffect(() => {
    getCountryInfo();
    getDivisionInfo();
    getDistrictInfo();
    getUpazilatInfo();
    getNationalityInfo();
  }, []);

  const handleSubmitPer = async (values) => {
    // var formdata = new FormData();

    let scrArr = [profileScoreArr[3]];

    if (applicantsInfo?.is_same_as_permanent_address === 1) {
      scrArr.push(profileScoreArr[2]);

      // formdata.append("pre_address_line_1", values?.per_address_line_1);
      // formdata.append("pre_divisions_id", values?.per_divisions_id);
      // formdata.append("pre_districts_id", values?.per_districts_id);
      // formdata.append("pre_thana_id", values?.per_thana_id);
      // formdata.append(
      //   "pre_international_address",
      //   values?.per_international_address
      // );
      values.pre_address_line_1 = values?.per_address_line_1;
      values.pre_divisions_id = values?.per_divisions_id;
      values.pre_districts_id = values?.per_districts_id;
      values.pre_thana_id = values?.per_thana_id;
      values.pre_international_address = values?.per_international_address;
    }

    // formdata.append("per_address_line_1", values?.per_address_line_1);
    // formdata.append("per_divisions_id", values?.per_divisions_id);
    // formdata.append("per_districts_id", values?.per_districts_id);
    // formdata.append("per_postal_code", values?.per_postal_code);
    // formdata.append("per_thana_id", values?.per_thana_id);
    // formdata.append(
    //   "per_international_address",
    //   values?.per_international_address
    // );

    await API.patch(
      `/hrms_applicant/update-profile/${user?.data?.applicant_id}`,
      values
    )
      .then((res) => {
        if (res?.data?.success) {
          swalSuccess("Data updated successfully!!");
          dispatch(Action.insertProfileScore(scrArr));
          // dispatch(Action.getProfilePercentage());
          dispatch(Action.getApplicantsInfoById(user?.data?.applicant_id));
          setShowEditOne(false);
        } else {
          swalError(res?.data?.message, " ");
        }
      })
      .catch((error) => {
        swalError("someting went wrong");
      });
  };

  const handlePreSubmit = async (values) => {
    // var formdata = new FormData();
    // for (let k in values) {
    //   if (values[k]) {
    //     formdata.append(k, values[k]);
    //   }
    // }
    // formdata.append('is_same_as_permanent_address', isChecked ? 1 : 0);

    await API.patch(
      `/hrms_applicant/update-profile/${user?.data?.applicant_id}`,
      values
    )
      .then((res) => {
        if (res?.data?.success) {
          swalSuccess("Data updated successfully!!");
          dispatch(Action.insertProfileScore([profileScoreArr[2]]));
          // dispatch(Action.getProfilePercentage());
          dispatch(Action.getApplicantsInfoById(user?.data?.applicant_id));
          setShowEditTwo(false);
        } else {
          swalError(res?.data?.message, " ");
        }
      })
      .catch((error) => {
        swalError("someting went wrong");
      });
  };

  const handleSubmit = async (values) => {
    let selectedElements = [];

    if (values?.is_ex_employee == 1) {
      selectedElements = profileScoreArr.slice(0, 2);
    } else {
      selectedElements = profileScoreArr.slice(0, 1);
    }
    values.height = values.height_ft
      ? `${values.height_ft}'${values.height_in || 0}"`
      : null;

    for (let k in values) {
      if (values[k]) {
        if (values["marital_status"] != "Married" && k == "spouse_name") {
          values[k] = "";
        } else if (
          k == "ex_employee_code" ||
          k == "ex_superviosor_name" ||
          k == "ex_superviosor_designation" ||
          k == "ex_superviosor_email" ||
          k == "ex_superviosor_phone"
        ) {
          if (values["is_ex_employee"] != 1) {
            values[k] =
              k == "prev_joining_date" || k == "prev_last_working_date"
                ? null
                : "";
          }
        } else if (k == "about_disability" && values["is_disable"] != 1) {
          values[k] = "";
        }
      }
    }

    let userData = {
      // email:values?.email,
      first_name: values?.first_name,
      middle_name: values?.middle_name,
      last_name: values?.last_name,
      contact_number: values?.contact_number,
    };

    await API.patch(`/hrms_applicant/${user?.data?.applicant_id}`, values)
      .then((res) => {
        if (res?.data?.success) {
          swalSuccess("Data updated successfully!!");
          dispatch(Action.updateUserData(userData, user?.data?.id));
          // dispatch(Action.getProfilePercentage());
          dispatch(Action.insertProfileScore(selectedElements));
          dispatch(Action.getApplicantsInfoById(user?.data?.applicant_id));
          setShowEdit(false);
        } else {
          swalError(res?.data?.message, " ");
        }
      })
      .catch((error) => {
        swalError("someting went wrong");
      });
  };

  const handleSubmitSocialMedia = async (values) => {
    values.social_medias = values.social_medias?.map((item) => ({
      ...item,
      icon: socialMediaDefined[item.platform],
    }));

    // const API_URL = 'http://localhost:6013/hrms_applicant_social_media';
    const API_URL = "/hrms_applicant_social_media";
    await API.post(API_URL, values)
      .then(async(res) => {
        if (res?.data?.success) {
          swalSuccess("Data updated successfully!!");
          dispatch(Action.getApplicantsSocialMedia(user?.data?.applicant_id));
          setShowEditSocialMedia(false);
          if(values.social_medias?.length) {
            await dispatch(Action.insertProfileScore(["Social Media"]));
            dispatch(Action.getApplicantsInfoById(user?.data?.applicant_id));
          } else {
            await dispatch(Action.updateProfileScore(["Social Media"]));
            dispatch(Action.getApplicantsInfoById(user?.data?.applicant_id));
          }
        } else {
          swalError(res?.data?.message, " ");
        }
      })
      .catch((error) => {
        swalError("someting went wrong");
      });
  };

  const countryListOption = countryList?.map(function (item) {
    return {
      value: item?.value,
      label: item?.label,
    };
  });

  const nationalityListOption = nationalityList?.map(function (item) {
    return {
      value: item?.value,
      label: item?.label,
    };
  });

  const divisionListOption = divisionList?.map(function (item) {
    return {
      value: item?.value,
      label: item?.label,
    };
  });

  const districtListOption = districtList?.map(function (item) {
    return {
      value: item?.value,
      label: item?.label,
    };
  });

  const upazilaListOption = upazilaList?.map(function (item) {
    return {
      value: item?.value,
      label: item?.label,
    };
  });

  const seoncN = nationalityList.filter(
    (col) => col?.value === applicantsInfo?.second_nationality_id
  );

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckSameAddress = (val, values, setFieldValue) => {
    setIsChecked(!isChecked);
    if (val) {
      setFieldValue("pre_address_line_1", applicantsInfo.per_address_line_1);
      setFieldValue("pre_districts_id", applicantsInfo.per_districts_id);
      setFieldValue("pre_divisions_id", applicantsInfo.per_divisions_id);
      setFieldValue("pre_thana_id", applicantsInfo?.per_hrms_thana?.id);
      setFieldValue("pre_postal_code", applicantsInfo?.per_postal_code);
      setFieldValue(
        "pre_international_address",
        applicantsInfo?.per_international_address
      );

      if(applicantsInfo.per_divisions_id) {
        dispatch(
          Action.getAllPreDistrictByDivision(applicantsInfo.per_divisions_id)
        );
      }
      if(applicantsInfo.per_divisions_id && applicantsInfo.per_districts_id) {
        dispatch(
          Action.getAllPreThanaDistrictByDivision(
            applicantsInfo.per_divisions_id,
            applicantsInfo.per_districts_id
          )
        );
      }
    } else {
      setFieldValue("pre_address_line_1", "");
      setFieldValue("pre_districts_id", "");
      setFieldValue("pre_divisions_id", null);
      setFieldValue("pre_thana_id", "");
      setFieldValue("pre_postal_code", "");
      setFieldValue("pre_international_address", "");
      setFieldValue("is_same_as_permanent_address", "");
    }
  };

  return (
    <div className="main_contents_div">
      <ProfileCard />
      <ProfileTabs />

      <TitleWithEditCard
        title="Personal Information"
        setShowEdit={setShowEdit}
        showEdit={showEdit}
      >
        {showEdit ? (
          <>
            <Formik
              initialValues={selectedData}
              validationSchema={PersonalSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ errors, touched, setFieldValue, values, isSubmitting, setFieldTouched }) => (
                <Form>
                  <div className="row mt-10">
                    <div className="col-md-12 mb-3">
                      <div className="col-md-4 p-0">
                        <label className="required">
                          <b>Job Category</b>
                        </label>
                        <Select
                          options={categories}
                          name="category_id"
                          placeholder="Select Job Category"
                          value={values.job_category ? values.job_category : categories?.find((option) => option.value === values?.category_id)}
                          onChange={(options) => {
                            setFieldValue(
                              "category_id",
                              options ? options.value : null
                            );
                            setFieldValue("job_category", options);
                            setTimeout(() => {
                              setFieldTouched("category_id");
                            }, "300");
                          }}
                          isClearable={true}
                        />
                        {errors.category_id ? (  
                          <div className="fv-plugins-message-container mb-3">
                            <div className="fv-help-block">
                              {errors.category_id}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>First Name</b>
                      </label>
                      <Field
                        name="first_name"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                      />
                      {errors.first_name && touched.first_name ? (
                        <div className="text-danger">{errors.first_name}</div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="">
                        <b>Middle Name</b>
                      </label>
                      <Field
                        name="middle_name"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                      />
                      {errors.middle_name && touched.middle_name ? (
                        <div className="text-danger">{errors.middle_name}</div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label>
                        <b>Last Name</b>
                      </label>
                      <Field
                        name="last_name"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                      />
                      {errors.last_name && touched.last_name ? (
                        <div className="text-danger">{errors.last_name}</div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>Date of Birth</b>
                      </label>
                      <Field
                        name="date_of_birth"
                        type="date"
                        className="form-control"
                        autoComplete="off"
                      />
                      {errors.date_of_birth && touched.date_of_birth ? (
                        <div className="text-danger">
                          {/* Date of Birth is */}
                          Required
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>Father’s Name</b>
                      </label>
                      <Field
                        name="father_name"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                      />
                      {errors.father_name && touched.father_name ? (
                        <div className="text-danger">{errors.father_name}</div>
                      ) : null}
                    </div>

                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>Mother’s Name</b>
                      </label>
                      <Field
                        name="mother_name"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                      />
                      {errors.mother_name && touched.mother_name ? (
                        <div className="text-danger">{errors.mother_name}</div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>Religion</b>
                      </label>
                      {/* <Field name="religion" type="text" className="form-control" autoComplete="off"/> */}
                      <AntdSelect
                        options={religions}
                        value={values.religion}
                        onChange={(value) => {
                          setFieldValue("religion", value);
                        }}
                        style={{ width: "100%" }}
                        allowClear={true}
                      />
                      {errors.religion && touched.religion && (
                        <div className="text-danger">{errors.religion}</div>
                      )}
                    </div>
                    {values.religion == "Other" && (
                      <div className="col-md-4 mb-3">
                        <label className="required">
                          <b>Other Religion</b>
                        </label>
                        <Field
                          name="other_religion_name"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                        />
                        {errors.other_religion_name &&
                          touched.other_religion_name && (
                            <div className="text-danger">
                              {errors.other_religion_name}
                            </div>
                          )}
                      </div>
                    )}
                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>Gender</b>
                      </label>
                      <Field name="gender" as="select" className="form-control">
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </Field>
                      {errors.gender && touched.gender ? (
                        <div className="text-danger">{errors.gender}</div>
                      ) : null}
                    </div>

                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>Marital Status</b>
                      </label>
                      <Field
                        name="marital_status"
                        as="select"
                        className="form-control"
                      >
                        <option value="">Select Marital status</option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Divorced ">Divorced </option>
                        <option value="Separated">Separated</option>
                        <option value="Widowed">Widowed</option>
                      </Field>
                      {errors.marital_status && touched.marital_status ? (
                        <div className="text-danger">
                          {errors.marital_status}
                        </div>
                      ) : null}
                    </div>
                    {values?.marital_status == "Married" && (
                      <>
                        <div className="col-md-4 mb-4">
                          <label className="required">
                            <b>Spouse Name</b>
                          </label>
                          <Field
                            name="spouse_name"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                          />
                          {errors.spouse_name && touched.spouse_name && (
                            <div className="text-danger">
                              {errors.spouse_name}
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>Country of Birth</b>
                      </label>
                      <Select
                        options={countryListOption}
                        placeholder="Select Country"
                        value={countryListOption.find(
                          (option) => option.value === values?.country_of_birth
                        )}
                        onChange={(options) => {
                          setFieldValue(
                            "country_of_birth",
                            options ? options.value : null
                          );
                        }}
                        isClearable={true}
                      />

                      {errors.country_of_birth && touched.country_of_birth ? (
                        <div className="text-danger">
                          {errors.country_of_birth}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>Nationality</b>
                      </label>
                      <Select
                        options={nationalityListOption}
                        placeholder="Select Nationality"
                        value={nationalityListOption.find(
                          (option) => option.value === values?.nationality_id
                        )}
                        onChange={(options) => {
                          setFieldValue(
                            "nationality_id",
                            options ? options.value : null
                          );
                        }}
                        isClearable={true}
                      />

                      {errors.nationality_id && touched.nationality_id ? (
                        <div className="text-danger">
                          {errors.nationality_id}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>Nationality Type</b>
                      </label>
                      <Field
                        name="nationality_type"
                        as="select"
                        className="form-control"
                      >
                        <option value="">Select Nationality Type</option>
                        <option value="By Birth">By Birth</option>
                        <option value="Migrated">Migrated</option>
                      </Field>
                      {errors.nationality_type && touched.nationality_type ? (
                        <div className="text-danger">
                          {errors.nationality_type}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="">
                        <b>2nd Nationality</b>
                      </label>
                      <Select
                        options={nationalityListOption}
                        placeholder="Select Nationality"
                        value={nationalityListOption.find(
                          (option) =>
                            option.value === values?.second_nationality_id
                        )}
                        onChange={(options) => {
                          setFieldValue(
                            "second_nationality_id",
                            options ? options.value : null
                          );
                        }}
                        isClearable={true}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="">
                        <b>2nd Nationality Type</b>
                      </label>
                      <Field
                        name="second_nationality_type"
                        as="select"
                        className="form-control"
                      >
                        <option value="">Select 2nd Nationality Type</option>
                        <option value="By Birth">By Birth</option>
                        <option value="Migrated">Migrated</option>
                      </Field>
                    </div>

                    <div className="col-md-4 mb-3">
                      <label
                        className={
                          values.nationality_id === 15 ? "required" : ""
                        }
                      >
                        <b>NID Number</b>
                      </label>
                      <Field
                        name="nid"
                        type="number"
                        className="form-control"
                        autoComplete="off"
                      />
                      {errors.nid && touched.nid ? (
                        <div className="text-danger">{errors.nid}</div>
                      ) : null}
                    </div>
                    {/* <div className="col-md-4 mb-3">
                                        <label className=""><b>Employee Code</b></label>
                                        <Field name="pin" type="text" className="form-control" />
                                    </div> */}
                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>Blood group</b>
                      </label>
                      <Field
                        name="blood_group"
                        as="select"
                        className="form-control"
                      >
                        <option value="">Select Blood Group</option>
                        <option value={"A+"}>A+</option>
                        <option value={"B+"}>B+</option>
                        <option value={"AB+"}>AB+</option>
                        <option value={"O+"}>O+</option>
                        <option value={"A-"}>A-</option>
                        <option value={"AB-"}>AB-</option>
                        <option value={"B-"}>B-</option>
                        <option value={"O-"}>O-</option>
                        <option value={"Unknown"}>Unknown</option>
                      </Field>
                      {
                        errors.blood_group && touched.blood_group && (
                        <div className="text-danger">
                          {errors.blood_group}
                        </div>
                        )
                      }
                    </div>

                    <div className="col-md-4 mb-3">
                      <label
                        className={
                          values.nationality_id !== 15 ? "required" : ""
                        }
                      >
                        <b>Passport Number</b>
                      </label>
                      <Field
                        name="passport_number"
                        // type="number"
                        className="form-control"
                        autoComplete="off"
                      />
                      {errors.passport_number && touched.passport_number ? (
                        <div className="text-danger">
                          {errors.passport_number}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>Contact Number</b>
                      </label>
                      {/* <Field name="contact_number" type="text" className="form-control" autoComplete="off"/> */}
                      <PhoneInput
                        defaultCountry="BD"
                        value={values.contact_number}
                        placeholder="Enter contact number"
                        flags={flags}
                        onChange={(value) => {
                          setFieldValue("contact_number", value);
                        }}
                      />
                      {errors.contact_number && touched.contact_number ? (
                        <div className="text-danger">
                          {errors.contact_number}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>Email</b>
                      </label>
                      <Field
                        name="email"
                        type="text"
                        className="form-control"
                        disabled={true}
                        autoComplete="off"
                      />
                      {errors.email && touched.email ? (
                        <div className="text-danger">{errors.email}</div>
                      ) : null}
                    </div>

                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>Whatsapp Number or Alternative Contact No.</b>
                      </label>
                      {/* <Field name="alternative_contact_number" type="text" className="form-control" autoComplete="off"/> */}
                      <PhoneInput
                        defaultCountry="BD"
                        value={values.alternative_contact_number}
                        placeholder="Enter whatsapp Number or alternative contact no."
                        flags={flags}
                        onChange={(value) => {
                          setFieldValue("alternative_contact_number", value);
                        }}
                      />
                      {errors.alternative_contact_number ? (
                        <div className="text-danger">
                          {errors.alternative_contact_number}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="">
                        <b>Alternative Email</b>
                      </label>
                      <Field
                        name="alternative_email"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                      />
                      {errors.alternative_email && touched.alternative_email ? (
                        <div className="text-danger">
                          {errors.alternative_email}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>Disability</b>
                      </label>
                      <Field
                        name="is_disable"
                        as="select"
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("is_disable", e.target.value);
                        }}
                      >
                        <option>Select Disability </option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </Field>
                      {errors.is_disable && touched.is_disable ? (
                        <div className="text-danger">{errors.is_disable}</div>
                      ) : null}
                    </div>

                    {values?.is_disable == 1 && (
                      <div className="col-md-4 mb-3">
                        <label className="required">
                          <b>Type of Disability</b>
                        </label>
                        <Field
                          name="about_disability"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                        />

                        {errors.about_disability && touched.about_disability ? (
                          <div className="text-danger">
                            {errors.about_disability}
                          </div>
                        ) : null}
                      </div>
                    )}

                    <div className="col-md-4 mb-3">
                      <label className="">
                        <b>Height</b>
                      </label>
                      {/* <Field name="height" type="number" className="form-control" autoComplete="off"/> */}
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group label-static">
                            <div class="input-group">
                              <input
                                id="height_stepper_ft"
                                name="height_stepper_ft"
                                class="form-control"
                                type="number"
                                min="0"
                                max="8"
                                step="1"
                                value={values.height_ft}
                                onChange={(e) => {
                                  setFieldValue("height_ft", e.target?.value);
                                }}
                              />
                              <span class="input-group-addon">ft</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group label-static">
                            <div class="input-group">
                              <input
                                id="height_stepper_in"
                                name="height_stepper_in"
                                class="form-control"
                                type="number"
                                min="0"
                                max="11"
                                step="1"
                                value={values.height_in}
                                onChange={(e) => {
                                  setFieldValue("height_in", e.target?.value);
                                }}
                              />
                              <span class="input-group-addon">in</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="">
                        <b>Weight(kg)</b>
                      </label>
                      <Field
                        name="weight"
                        type="number"
                        className="form-control"
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="">
                        <b>Tax Identification Number</b>
                      </label>
                      <Field
                        name="tin"
                        type="number"
                        className="form-control"
                        autoComplete="off"
                      />
                    </div>
                    {/* <div className="col-md-4 mb-3">
                                        <label className=""><b>No of Living Child</b></label>
                                        <Field name="no_of_living_child" type="number" className="form-control" autoComplete="off"/>
                                    </div> */}
                    <div className="col-md-4 mb-3">
                      <label className="">
                        <b>Work Permit Issue Date (If Foreigner)</b>
                      </label>
                      <Field
                        name="work_permit_issue_date"
                        onChange={(event) => {
                          setFieldValue(
                            "work_permit_issue_date",
                            event.target.value || null
                          );
                          setFieldValue("work_permit_expiry_date", null);
                        }}
                        type="date"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="">
                        <b>Work Permit Expiry Date (If Foreigner)</b>
                      </label>
                      <Field
                        name="work_permit_expiry_date"
                        type="date"
                        className="form-control"
                        onChange={(event) => {
                          if (values.work_permit_issue_date) {
                            setFieldValue(
                              "work_permit_expiry_date",
                              event.target.value || null
                            );
                          }
                        }}
                      />
                      {errors.work_permit_expiry_date &&
                      touched.work_permit_expiry_date ? (
                        <div className="text-danger">
                          {errors.work_permit_expiry_date}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="">
                        <b>Passport Issue Date</b>
                      </label>
                      <Field
                        name="passport_issue_date"
                        type="date"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue(
                            "passport_issue_date",
                            event.target.value || null
                          );
                          setFieldValue("passport_expiry_date", null);
                        }}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="">
                        <b>Passport Expiry Date</b>
                      </label>
                      <Field
                        name="passport_expiry_date"
                        type="date"
                        className="form-control"
                        onChange={(event) => {
                          if (values.passport_issue_date) {
                            setFieldValue(
                              "passport_expiry_date",
                              event.target.value || null
                            );
                          }
                        }}
                      />
                      {errors.passport_expiry_date &&
                      touched.passport_expiry_date ? (
                        <div className="text-danger">
                          {errors.passport_expiry_date}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="">
                        <b>Visa Expiry Date (If Foreigner)</b>
                      </label>
                      <Field
                        name="visa_expiry_date"
                        type="date"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue(
                            "visa_expiry_date",
                            event.target.value || null
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-10">
                    <div className="col-md-4 mb-3">
                      <label className="required">
                        <b>Are you an Ex BRACU Employee?</b>
                      </label>
                      <Field
                        name="is_ex_employee"
                        as="select"
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("is_ex_employee", e.target.value);
                        }}
                      >
                        <option>Select </option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </Field>
                      {errors.is_ex_employee && touched.is_ex_employee ? (
                        <div className="text-danger">
                          {errors.is_ex_employee}
                        </div>
                      ) : null}
                    </div>

                    {values?.is_ex_employee == 1 && (
                      <>
                        <div className="col-md-4 mb-3">
                          <label className="required">
                            <b>BRACU PIN</b>
                          </label>
                          <Field
                            name="ex_employee_code"
                            type="number"
                            className="form-control"
                            autoComplete="off"
                          />

                          {errors.ex_employee_code &&
                          touched.ex_employee_code ? (
                            <div className="text-danger">
                              {errors.ex_employee_code}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-4 mb-3">
                          <label className="required">
                            <b>Joining Date</b>
                          </label>
                          <Field
                            name="prev_joining_date"
                            type="date"
                            className="form-control"
                            autoComplete="off"
                            onChange={(event) => {
                              setFieldValue(
                                "prev_joining_date",
                                event.target.value || null
                              );
                            }}
                          />

                          {errors.prev_joining_date &&
                          touched.prev_joining_date ? (
                            <div className="text-danger">
                              {errors.prev_joining_date}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-4 mb-3">
                          <label className="required">
                            <b>Last Working Day</b>
                          </label>
                          <Field
                            name="prev_last_working_date"
                            type="date"
                            className="form-control"
                            autoComplete="off"
                            onChange={(event) => {
                              setFieldValue(
                                "prev_last_working_date",
                                event.target.value || null
                              );
                            }}
                          />

                          {errors.prev_last_working_date &&
                          touched.prev_last_working_date ? (
                            <div className="text-danger">
                              {errors.prev_last_working_date}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-4 mb-3">
                          <label>
                            <b>Supervisor Name</b>
                          </label>
                          <Field
                            name="ex_superviosor_name"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                          />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>
                            <b>Supervisor Designation</b>
                          </label>
                          <Field
                            name="ex_superviosor_designation"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                          />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>
                            <b>Supervisor Email</b>
                          </label>
                          <Field
                            name="ex_superviosor_email"
                            type="email"
                            className="form-control"
                            autoComplete="off"
                          />
                          {errors.ex_superviosor_email &&
                          touched.ex_superviosor_email ? (
                            <div className="text-danger">
                              {errors.ex_superviosor_email}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-4 mb-3">
                          <label>
                            <b>Supervisor Phone</b>
                          </label>
                          <Field
                            name="ex_superviosor_phone"
                            maxLength="11"
                            type="text"
                            className="form-control"
                            autoComplete="off"
                          />
                          {errors.ex_superviosor_phone &&
                          touched.ex_superviosor_phone ? (
                            <div className="text-danger">
                              {errors.ex_superviosor_phone}
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row mt-10">
                    <div className="col-md-12 mb-3 text-right">
                      <button
                        className="btn btn-default mr-3"
                        type="button"
                        onClick={() => {
                          setShowEdit(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {
                          isSubmitting ? (
                            <span><i className="fa fa-sync fa-spin"></i> Updating</span>
                          ) : "Update"
                        }
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-md-6">
                <ItemsLabelRow
                  lable="Job Category"
                  filed={applicantsInfo?.m_hrms_applicant_cateogry?.name}
                />
                <ItemsLabelRow
                  lable="First Name"
                  filed={applicantsInfo?.m_career_portal_user?.first_name}
                />
                <ItemsLabelRow
                  lable="Middle Name"
                  filed={applicantsInfo?.m_career_portal_user?.middle_name}
                />
                <ItemsLabelRow
                  lable="Last Name"
                  filed={applicantsInfo?.m_career_portal_user?.last_name}
                />
                <ItemsLabelRow
                  lable="Father’s Name"
                  filed={applicantsInfo?.father_name}
                />
                <ItemsLabelRow lable="Gender" filed={applicantsInfo?.gender} />
                <ItemsLabelRow
                  lable="Marital Status"
                  filed={applicantsInfo?.marital_status}
                />
                {applicantsInfo?.marital_status == "Married" && (
                  <>
                    <ItemsLabelRow
                      lable="Spouse Name"
                      filed={applicantsInfo?.spouse_name}
                    />
                  </>
                )}
                <div className="card_items_row_flex">
                  <div className="label_box">Nationality </div>
                  <div className="item_data">
                    <strong>: </strong>
                    {applicantsInfo?.hrms_nationality?.name}
                    {/* - {applicantsInfo?.hrms_nationality?.id} */}
                  </div>
                </div>
                <div className="card_items_row_flex">
                  <div className="label_box">NID Number</div>
                  <div className="item_data">
                    <strong>: </strong>
                    {applicantsInfo?.nid}
                  </div>
                </div>
                <div className="card_items_row_flex">
                  <div className="label_box">Contact No.</div>
                  <div className="item_data">
                    <strong>: </strong>
                    {applicantsInfo?.m_career_portal_user?.contact_number}
                  </div>
                </div>
                <div className="card_items_row_flex">
                  <div className="label_box">Email</div>
                  <div className="item_data">
                    <strong>: </strong>
                    {applicantsInfo?.email}
                  </div>
                </div>
                <div className="card_items_row_flex">
                  <div className="label_box">Blood Group</div>
                  <div className="item_data">
                    <strong>: </strong>
                    {applicantsInfo?.blood_group}
                  </div>
                </div>
                <ItemsLabelRow
                  lable="2nd Nationality Type"
                  filed={applicantsInfo?.second_nationality_type}
                />
                <div className="card_items_row_flex">
                  <div className="label_box">Disability</div>
                  <div className="item_data">
                    <strong>: </strong>
                    <strong>
                      {applicantsInfo?.is_disable === 1 ? "Yes" : "No"}
                    </strong>

                    {applicantsInfo?.is_disable === 1 && (
                      <small> ({applicantsInfo?.about_disability})</small>
                    )}
                  </div>
                </div>
                {/* <ItemsLabelRow lable="No of Living Child" filed={applicantsInfo?.no_of_living_child} /> */}
                <ItemsLabelRow
                  lable="Work Permit Expiry Date (If Foreigner)"
                  filed={
                    applicantsInfo?.work_permit_expiry_date
                      ? moment(applicantsInfo?.work_permit_expiry_date).format(
                          "DD-MM-YYYY"
                        )
                      : ""
                  }
                />
                <ItemsLabelRow
                  lable="Work Permit Issue Date (If Foreigner)"
                  filed={
                    applicantsInfo?.work_permit_issue_date
                      ? moment(applicantsInfo?.work_permit_issue_date).format(
                          "DD-MM-YYYY"
                        )
                      : ""
                  }
                />
              </div>
              <div className="col-md-6">
                <ItemsLabelRow
                  lable="Date of Birth"
                  filed={
                    applicantsInfo?.date_of_birth
                      ? moment(applicantsInfo?.date_of_birth).format(
                          "DD-MM-YYYY"
                        )
                      : ""
                  }
                />
                <ItemsLabelRow
                  lable="Mother’s Name"
                  filed={applicantsInfo?.mother_name}
                />
                <ItemsLabelRow
                  lable="Religion"
                  filed={applicantsInfo?.religion}
                />
                {applicantsInfo?.religion === "Other" ? (
                  <ItemsLabelRow
                    lable="Other Religion"
                    filed={applicantsInfo?.other_religion_name}
                  />
                ) : (
                  <></>
                )}
                <ItemsLabelRow
                  lable="Country of Birth"
                  filed={applicantsInfo?.m_config_countries?.country_name}
                />
                <ItemsLabelRow
                  lable="Nationality Type"
                  filed={applicantsInfo?.nationality_type}
                />
                {/* <ItemsLabelRow lable="Employee Code" filed={applicantsInfo?.pin} /> */}
                <ItemsLabelRow
                  lable="Passport"
                  filed={applicantsInfo?.passport_number}
                />
                <ItemsLabelRow
                  lable="Alt. Contact No."
                  filed={applicantsInfo?.alternative_contact_number}
                />
                <ItemsLabelRow
                  lable="Alt Email"
                  filed={applicantsInfo?.alternative_email}
                />
                <div className="card_items_row_flex">
                  <div className="label_box">2nd Nationality </div>
                  <div className="item_data">
                    <strong>: </strong>
                    {seoncN && seoncN[0] && seoncN[0].label}
                    {/* {applicantsInfo?.second_nationality_id} */}
                  </div>
                </div>
                <ItemsLabelRow
                  lable="Height(ft)"
                  filed={applicantsInfo?.height}
                />
                <ItemsLabelRow
                  lable="Weight(kg)"
                  filed={applicantsInfo?.weight}
                />
                <ItemsLabelRow
                  lable="Tax Identification Number"
                  filed={applicantsInfo?.tin}
                />
                <ItemsLabelRow
                  lable="Passport Issue Date"
                  filed={
                    applicantsInfo?.passport_issue_date
                      ? moment(applicantsInfo?.passport_issue_date).format(
                          "DD-MM-YYYY"
                        )
                      : ""
                  }
                />
                <ItemsLabelRow
                  lable="Passport Expiry Date"
                  filed={
                    applicantsInfo?.passport_expiry_date
                      ? moment(applicantsInfo?.passport_expiry_date).format(
                          "DD-MM-YYYY"
                        )
                      : ""
                  }
                />
                <ItemsLabelRow
                  lable="Visa Expiry Date (If Foreigner)"
                  filed={
                    applicantsInfo?.visa_expiry_date
                      ? moment(applicantsInfo?.visa_expiry_date).format(
                          "DD-MM-YYYY"
                        )
                      : ""
                  }
                />
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col-md-6">
                <div className="card_items_row_flex">
                  <div className="label_box">Are you an Ex BRACU Employee?</div>
                  <div className="item_data">
                    <strong>: </strong>
                    {applicantsInfo?.is_ex_employee === 1 ? "Yes" : "No"}
                  </div>
                </div>
                {applicantsInfo?.is_ex_employee === 1 && (
                  <>
                    <div className="card_items_row_flex">
                      <div className="label_box">Joining Date</div>
                      <div className="item_data">
                        <strong>: </strong>
                        {applicantsInfo?.prev_joining_date
                          ? moment(applicantsInfo?.prev_joining_date).format(
                              "DD-MM-YYYY"
                            )
                          : ""}
                      </div>
                    </div>
                    <ItemsLabelRow
                      lable="Supervisor Name:"
                      filed={applicantsInfo?.ex_superviosor_name}
                    />
                    <div className="card_items_row_flex">
                      <div className="label_box">Supervisor Designation:</div>
                      <div className="item_data">
                        <strong>: </strong>
                        {applicantsInfo?.ex_superviosor_designation}
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="col-md-6">
                {applicantsInfo?.is_ex_employee === 1 && (
                  <>
                    <div className="card_items_row_flex">
                      <div className="label_box">BRACU PIN</div>
                      <div className="item_data">
                        <strong>: </strong>
                        {applicantsInfo?.ex_employee_code}
                      </div>
                    </div>
                    {/* <ItemsLabelRow lable="Currently Brac Employee:" filed={!applicantsInfo?.is_currently_or_prev_employeed_at_brac === 0 ? ("Yes") : ("No")} /> */}
                    {applicantsInfo?.prev_last_working_date === null ? (
                      <ItemsLabelRow lable="Last Working Day" filed={""} />
                    ) : (
                      <ItemsLabelRow
                        lable="Last Working Day"
                        filed={
                          applicantsInfo?.prev_last_working_date
                            ? moment(
                                applicantsInfo?.prev_last_working_date
                              ).format("DD-MM-YYYY")
                            : " "
                        }
                      />
                    )}

                    <ItemsLabelRow
                      lable="Supervisor Phone"
                      filed={applicantsInfo?.ex_superviosor_phone}
                    />
                    <ItemsLabelRow
                      lable="Supervisor Email"
                      filed={applicantsInfo?.ex_superviosor_email}
                    />
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </TitleWithEditCard>

      <TitleWithEditCard
        title="Social Media Information"
        setShowEdit={setShowEditSocialMedia}
        showEdit={showEditSocialMedia}
      >
        {showEditSocialMedia ? (
          <Formik
            initialValues={socialMedias}
            validationSchema={SocialMediaSchema}
            onSubmit={(values) => handleSubmitSocialMedia(values)}
            enableReinitialize={true}
          >
            {({ errors, touched, setFieldValue, values, isSubmitting }) => (
              <Form>
                <div className="row mt-10">
                  <div className="col-md-6">
                    <AntdSelect
                      placeholder="Add social media"
                      options={socialMediaOptions}
                      style={{ width: "100%" }}
                      onChange={(value, option) => {
                        setFieldValue("social_medias", [
                          ...values.social_medias,
                          option,
                        ]);
                      }}
                    />
                  </div>
                </div>
                {values.social_medias?.map((item, i) => {
                  return (
                    <div className="row mt-10">
                      <div className="col-md-4 mb-3">
                        <label className="required">
                          <b>Social Media Name</b>
                        </label>
                        {socialMediaDefined[item.platform] ? (
                          <div>
                            {item.platform == "X" ? (
                              <div style={{ width: "50px", height: "50px" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  width="100%"
                                  height="100%"
                                  viewBox="0,0,256,256"
                                >
                                  <g
                                    fill="#b5b5c3"
                                    fill-rule="nonzero"
                                    stroke="none"
                                    stroke-width="1"
                                    stroke-linecap="butt"
                                    stroke-linejoin="miter"
                                    stroke-miterlimit="10"
                                    stroke-dasharray=""
                                    stroke-dashoffset="0"
                                    font-family="none"
                                    font-weight="none"
                                    font-size="none"
                                    text-anchor="none"
                                  >
                                    <g transform="scale(5.12,5.12)">
                                      <path d="M5.91992,6l14.66211,21.375l-14.35156,16.625h3.17969l12.57617,-14.57812l10,14.57813h12.01367l-15.31836,-22.33008l13.51758,-15.66992h-3.16992l-11.75391,13.61719l-9.3418,-13.61719zM9.7168,8h7.16406l23.32227,34h-7.16406z"></path>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            ) : (
                              <i
                                style={{ fontSize: "3.5rem" }}
                                className={`${
                                  socialMediaDefined[item.platform]
                                }`}
                              ></i>
                            )}
                          </div>
                        ) : (
                          <Field
                            name="platform"
                            type="text"
                            value={item.platform}
                            className="form-control"
                            onChange={(event) => {
                              setFieldValue(
                                `social_medias.${i}.platform`,
                                event.target.value
                              );
                            }}
                          />
                        )}
                        {errors.social_medias &&
                        errors.social_medias.length > 0 &&
                        errors.social_medias[i]?.platform ? (
                          <div className="text-danger">
                            {errors.social_medias[i].platform}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-4 mb-3">
                        <label className="required">
                          <b>URL</b>
                        </label>
                        <Field
                          name="url"
                          type="text"
                          className="form-control"
                          value={item.url}
                          onChange={(event) => {
                            setFieldValue(
                              `social_medias.${i}.url`,
                              event.target.value
                            );
                          }}
                        />
                        {errors.social_medias &&
                        errors.social_medias.length > 0 &&
                        errors.social_medias[i]?.url ? (
                          <div className="text-danger">
                            {errors.social_medias[i].url}
                          </div>
                        ) : null}
                      </div>
                      <div
                        className="col-md-4"
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={() => {
                            setFieldValue(
                              "social_medias",
                              values?.social_medias?.filter(
                                (data, index) => index !== i
                              )
                            );
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  );
                })}
                <div className="row mt-10">
                  <div className="col-md-12 mb-3 text-right">
                    <button
                      className="btn btn-default mr-3"
                      type="button"
                      onClick={() => {
                        setShowEditSocialMedia(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {
                        isSubmitting ? (
                          <span><i className="fa fa-sync fa-spin"></i> Updating</span>
                        ) : 'Update'
                      }
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div className="row mt-10">
            <div className="col-md-12">
              {socialMedias?.social_medias?.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th>Social Media Name</th>
                      <th>URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {socialMedias?.social_medias?.map((media, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {socialMediaDefined[media.platform] ? (
                              <Tooltip
                                title={media.platform}
                                placement="topLeft"
                              >
                                <div>
                                  {media.platform == "X" ? (
                                    <div
                                      style={{ width: "50px", height: "50px" }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        width="100%"
                                        height="100%"
                                        viewBox="0,0,256,256"
                                      >
                                        <g
                                          fill="#b5b5c3"
                                          fill-rule="nonzero"
                                          stroke="none"
                                          stroke-width="1"
                                          stroke-linecap="butt"
                                          stroke-linejoin="miter"
                                          stroke-miterlimit="10"
                                          stroke-dasharray=""
                                          stroke-dashoffset="0"
                                          font-family="none"
                                          font-weight="none"
                                          font-size="none"
                                          text-anchor="none"
                                        >
                                          <g transform="scale(5.12,5.12)">
                                            <path d="M5.91992,6l14.66211,21.375l-14.35156,16.625h3.17969l12.57617,-14.57812l10,14.57813h12.01367l-15.31836,-22.33008l13.51758,-15.66992h-3.16992l-11.75391,13.61719l-9.3418,-13.61719zM9.7168,8h7.16406l23.32227,34h-7.16406z"></path>
                                          </g>
                                        </g>
                                      </svg>
                                    </div>
                                  ) : (
                                    <i
                                      style={{ fontSize: "3.5rem" }}
                                      className={`${
                                        socialMediaDefined[media.platform]
                                      }`}
                                    ></i>
                                  )}
                                </div>
                              </Tooltip>
                            ) : (
                              <h4>{media.platform}</h4>
                            )}
                          </td>
                          <td className="align-middle">
                            <a
                              href={media.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fas fa-external-link-alt text-primary"></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </TitleWithEditCard>

      <TitleWithEditCard
        title="Permanent Address (As Per NID/Passport)"
        setShowEdit={setShowEditOne}
        showEdit={showEditOne}
      >
        {showEditOne ? (
          <>
            <Formik
              initialValues={perInitialData}
              enableReinitialize={true}
              validationSchema={PermanentAddressSchema}
              onSubmit={(values) => handleSubmitPer(values)}
            >
              {({ errors, setFieldValue, touched, values, isSubmitting }) => {
                return (
                  <Form>
                    {/* {JSON.stringify(values)} */}
                    <div className="row mt-10">
                      <div className="col-md-4 mb-3">
                        <label className="required d-flex">
                          <b>Full Address</b>
                          <Checkbox
                            className="ml-3"
                            checked={values.per_international_address === "true"}
                            onChange={(e) => {
                              setFieldValue(
                                "per_international_address",
                                e.target.checked?.toString()
                              );
                            }}
                          >
                            International Address
                          </Checkbox>
                        </label>
                        <Field
                          name="per_address_line_1"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                        />
                        {errors.per_address_line_1 &&
                        touched.per_address_line_1 ? (
                          <div className="text-danger">
                            {errors.per_address_line_1}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-4 mb-3">
                        <label className="">
                          <b>Division</b>
                        </label>
                        <Select
                          options={divisionListOption}
                          placeholder="Select Division"
                          defaultValue={divisionListOption?.find(
                            (option) =>
                              option.value === values?.per_divisions_id
                          )}
                          onChange={(options) => {
                            setFieldValue(
                              "per_divisions_id",
                              options ? options.value : null
                            );
                            if (!options) {
                              setFieldValue("per_districts_id", null);
                              setFieldValue("per_thana_id", null);
                            } else {
                              setFieldValue("per_districts_id", null);
                              setFieldValue("per_thana_id", null);
                              dispatch(
                                Action.getAllPerDistrictByDivision(
                                  options ? options.value : null
                                )
                              );
                            }
                          }}
                          isClearable={true}
                        />

                        {errors.per_divisions_id && touched.per_divisions_id ? (
                          <div className="text-danger">
                            {errors.per_divisions_id}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-4 mb-3">
                        <label className="">
                          <b>District</b>
                        </label>
                        <Select
                          options={!values.per_divisions_id ? [] : per_district}
                          placeholder="Select District"
                          value={
                            per_district?.find(
                              (option) =>
                                option.value === values?.per_districts_id
                            ) ?? null
                          }
                          onChange={(options) => {
                            setFieldValue(
                              "per_districts_id",
                              options ? options.value : null
                            );
                            setFieldValue("per_thana_id", null);
                            dispatch(
                              Action.getAllPerThanaDistrictByDivision(
                                values.per_divisions_id,
                                options.value
                              )
                            );
                          }}
                          isClearable={true}
                        />

                        {errors.per_districts_id && touched.per_districts_id ? (
                          <div className="text-danger">
                            {errors.per_districts_id}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-4 mb-3">
                        <label className="">
                          <b>Thana/ Upazila</b>
                        </label>
                        <Select
                          options={
                            (!values.per_divisions_id || !values?.per_districts_id)
                              ? []
                              : per_thana
                          }
                          placeholder="Select Thana/ Upazila"
                          value={
                            per_thana?.find(
                              (option) => option.value == values?.per_thana_id
                            ) ?? null
                          }
                          onChange={(options) => {
                            setFieldValue(
                              "per_thana_id",
                              options ? options.value : null
                            );
                          }}
                          isClearable={true}
                        />
                        {errors.per_thana_id && touched.per_thana_id ? (
                          <div className="text-danger">
                            {errors.per_thana_id}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-4 mb-3">
                        <label className="">
                          <b>Postal Code</b>
                        </label>
                        <Field
                          name="per_postal_code"
                          type="number"
                          className="form-control"
                          autoComplete="off"
                        />
                        {errors.per_postal_code && touched.per_postal_code ? (
                          <div className="text-danger">
                            {errors.per_postal_code}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row mt-10">
                      <div className="col-md-12 mb-3 text-right">
                        <button
                          className="btn btn-default mr-3"
                          type="button"
                          onClick={() => {
                            setShowEditOne(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {
                            isSubmitting ? (
                              <span><i className="fa fa-sync fa-spin"></i> Updating</span>
                            ) : "Update"
                          }
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-md-6">
                <ItemsLabelRow
                  lable="Full Address"
                  filed={applicantsInfo?.per_address_line_1}
                />
                <ItemsLabelRow
                  lable="Division"
                  filed={
                    applicantsInfo?.per_sys_geo_divisions?.geo_division_name
                  }
                />
                <ItemsLabelRow
                  lable="Thana/Upazilla"
                  filed={applicantsInfo?.per_hrms_thana?.name}
                />
              </div>

              <div className="col-md-6">
                <ItemsLabelRow
                  lable="District"
                  filed={
                    applicantsInfo?.per_sys_geo_districts?.geo_district_name
                  }
                />
                <ItemsLabelRow
                  lable="Postal Code"
                  filed={applicantsInfo?.per_postal_code}
                />
                <ItemsLabelRow
                  lable="International Address"
                  filed={
                    applicantsInfo?.per_international_address == "true"
                    ? "Yes" 
                    : applicantsInfo?.per_international_address == "false" 
                    ? "No" 
                    : ''
                  }
                />
              </div>
            </div>
          </>
        )}
      </TitleWithEditCard>

      <TitleWithEditCard
        title="Present Address"
        setShowEdit={setShowEditTwo}
        showEdit={showEditTwo}
      >
        {showEditTwo ? (
          <>
            <Formik
              initialValues={preInitialData}
              validationSchema={PresentAddressSchema}
              onSubmit={(values) => handlePreSubmit(values)}
            >
              {({ errors, touched, setFieldValue, values, isSubmitting }) => (
                <Form>
                  {/* {JSON.stringify(values)} */}
                  <div className="row mt-10">
                    <div className="col-md-12 mb-5">
                      <Field
                        type="checkbox"
                        checked={isChecked}
                        id="sameAddress"
                        onChange={(e) =>
                          handleCheckSameAddress(
                            e.target.checked,
                            values,
                            setFieldValue
                          )
                        }
                      />
                      <label
                        className="form-check-label ml-2"
                        htmlFor="sameAddress"
                      >
                        Same as permanent address{" "}
                      </label>
                    </div>

                    <Fragment>
                      <div className="col-md-4 mb-3">
                        <label className="required d-flex">
                          <b>Full Address</b>
                          <Checkbox
                            className="ml-3"
                            checked={values.pre_international_address === "true"}
                            onChange={(e) => {
                              setFieldValue(
                                "pre_international_address",
                                e.target.checked?.toString()
                              );
                            }}
                          >
                            International Address
                          </Checkbox>
                        </label>
                        <Field
                          name="pre_address_line_1"
                          type="text"
                          className="form-control"
                          autoComplete="off"
                        />
                        {errors.pre_address_line_1 &&
                        touched.pre_address_line_1 ? (
                          <div className="text-danger">
                            {errors.pre_address_line_1}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-4 mb-3">
                        <label className="">
                          <b>Division</b>
                        </label>
                        <Select
                          options={divisionListOption}
                          placeholder="Select Division"
                          value={
                            divisionListOption?.find(
                              (option) =>
                                option.value === values?.pre_divisions_id
                            ) ?? null
                          }
                          onChange={(options) => {
                            setFieldValue(
                              "pre_divisions_id",
                              options ? options.value : null
                            );
                            if (!options) {
                              setFieldValue("pre_districts_id", null);
                              setFieldValue("pre_thana_id", null);
                            } else {
                              setFieldValue("pre_districts_id", null);
                              setFieldValue("pre_thana_id", null);
                              dispatch(
                                Action.getAllPreDistrictByDivision(
                                  options ? options.value : null
                                )
                              );
                            }
                          }}
                          isClearable={true}
                        />
                        {errors.pre_divisions_id && touched.pre_divisions_id ? (
                          <div className="text-danger">
                            {errors.pre_divisions_id}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-4 mb-3">
                        <label className="">
                          <b>District</b>
                        </label>
                        <Select
                          options={!values.pre_divisions_id ? [] : pre_district}
                          placeholder="Select Division"
                          value={
                            pre_district?.find(
                              (option) =>
                                option.value === values?.pre_districts_id
                            ) ?? null
                          }
                          onChange={(options) => {
                            setFieldValue(
                              "pre_districts_id",
                              options ? options.value : null
                            );
                            setFieldValue("pre_thana_id", null);
                            dispatch(
                              Action.getAllPreThanaDistrictByDivision(
                                values.pre_divisions_id,
                                options.value
                              )
                            );
                          }}
                          isClearable={true}
                        />
                        {errors.pre_districts_id && touched.pre_districts_id ? (
                          <div className="text-danger">
                            {errors.pre_districts_id}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-4 mb-3">
                        <label className="">
                          <b>Thana/ Upazila</b>
                        </label>
                        <Select
                          options={
                            !values.pre_divisions_id || !values.pre_districts_id
                              ? []
                              : pre_thana
                          }
                          placeholder="Select Thana/ Upazila"
                          value={
                            pre_thana?.find(
                              (option) => option.value === values?.pre_thana_id
                            ) ?? null
                          }
                          onChange={(options) => {
                            setFieldValue(
                              "pre_thana_id",
                              options ? options.value : null
                            );
                          }}
                          isClearable={true}
                        />

                        {errors.pre_thana_id && touched.pre_thana_id ? (
                          <div className="text-danger">
                            {errors.pre_thana_id}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-4 mb-3">
                        <label className="">
                          <b>Postal Code</b>
                        </label>
                        <Field
                          name="pre_postal_code"
                          type="number"
                          className="form-control"
                          autoComplete="off"
                        />
                        {errors.pre_postal_code && touched.pre_postal_code ? (
                          <div className="text-danger">
                            {errors.pre_postal_code}
                          </div>
                        ) : null}
                      </div>
                    </Fragment>
                  </div>

                  <div className="row mt-10">
                    <div className="col-md-12 mb-3 text-right">
                      <button
                        className="btn btn-default mr-3"
                        type="button"
                        onClick={() => {
                          setShowEditTwo(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {
                          isSubmitting ? (
                            <span><i className="fa fa-sync fa-spin"></i> Updating</span>
                          ) : "Update"
                        }
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <div className="row">
            <div className="col-md-6">
              <ItemsLabelRow
                lable="Full Address"
                filed={applicantsInfo?.pre_address_line_1}
              />
              <ItemsLabelRow
                lable="Division"
                filed={applicantsInfo?.pre_sys_geo_divisions?.geo_division_name}
              />
              <ItemsLabelRow
                lable="Thana/Upazilla"
                filed={applicantsInfo?.pre_hrms_thana?.name}
              />
            </div>

            <div className="col-md-6">
              <ItemsLabelRow
                lable="District"
                filed={applicantsInfo?.pre_sys_geo_districts?.geo_district_name}
              />
              <ItemsLabelRow
                lable="Postal Code"
                filed={applicantsInfo?.pre_postal_code}
              />
              <ItemsLabelRow
                lable="International Address"
                filed={
                    applicantsInfo?.pre_international_address == "true"
                    ? "Yes" 
                    : applicantsInfo?.pre_international_address == "false" 
                    ? "No" 
                    : ''
                }
              />
            </div>
          </div>
        )}
      </TitleWithEditCard>
    </div>
  );
}
